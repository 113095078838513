@import "../../../../ba_gp_storefront_core/cartridge/scss/default/mixin/breakpoint";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/variables";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/blueacorn/var";

// RichText component

$ex: ex-richtext;

.#{$ex} {
    .richtextComponent {
        color: var(--color);
        top: var(--top);
        left: var(--left);
        max-width: var(--max-width);
        font-weight: var(--font-weight);
        letter-spacing: var(--letter-spacing);

        @include bp(min-width, $bp-medium) {
            left: var(--left-md);
            max-width: var(--max-width-md);
            top: var(--top-md);
            font-weight: var(--font-weight-md);
            letter-spacing: var(--letter-spacing-md);
        }

        > * {
            color: inherit;
            font: inherit;
            letter-spacing: inherit;
            text-transform: none;

            a {
                color: inherit;
                font: inherit;
                letter-spacing: inherit;
                text-decoration: underline;
            }
        }
    }

    &--cm-0 > * {
        margin: 0;
    }

    &--divider {
        border-right: 1px solid $another-gray;
    }
}

.page-designer-reference {
    .#{$ex} {
        &--cm-0 {
            > * {
                margin: 0;
            }
        }
    }
}
